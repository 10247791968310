// import { importHighcharts } from '@/lib/highcharts'
// import { BlockModule } from '../common'
// import {
//     baseLineChartConfig,
//     chartLineColors,
//     doIfInViewOrDefer,
//     setupChartCounters,
//     trendColorClasses,
//     trendIconClasses,
// } from './common'
// import { getMarketDataLabourParticipationRate } from '@/api'
// import { computed, defineComponent, onBeforeUnmount, onMounted, ref, unref, watch } from 'vue'
// import ChartShareButtons from '@/components/website/ChartShareButtons.vue'
// import {
//     Select,
//     SelectContent,
//     SelectGroup,
//     SelectItem,
//     SelectLabel,
//     SelectTrigger,
//     SelectValue,
// } from '@/components/ui/select'
// import { useBlockContext } from '../common/composables'
// import { format, parseISO } from 'date-fns'
// import { computedAsync } from '@vueuse/core'
// import type { Chart, SeriesOptionsType } from 'highcharts'

// new BlockModule({
//     selector: '.block-market-data-labour-participation-rate',
//     vueComponent: defineComponent({
//         components: {
//             ChartShareButtons,
//             ShadcnSelect: Select,
//             SelectContent,
//             SelectGroup,
//             SelectItem,
//             SelectLabel,
//             SelectTrigger,
//             SelectValue,
//         },
//         setup() {
//             const chartContainerRef = ref<HTMLElement>()
//             const year = ref(new Date().getFullYear().toString())
//             let chartInstance: Chart | null = null
//             const blockContext = useBlockContext()

//             const dataAsync = computedAsync(async (onCancel) => {
//                 const abortController = new AbortController()

//                 onCancel(() => abortController.abort())

//                 const data = await getMarketDataLabourParticipationRate(
//                     unref(year),
//                     abortController.signal,
//                 )

//                 return data
//             })

//             const lastUpdatedLabel = computed(() => {
//                 if (!dataAsync.value) return
//                 return format(dataAsync.value.lastUpdated, 'PP')
//             })

//             const seriesData = computed<SeriesOptionsType[]>(() => {
//                 if (!dataAsync.value) return []
//                 return [
//                     {
//                         name: dataAsync.value.yLabel,
//                         type: 'line',
//                         color: '#010066',
//                         data: dataAsync.value.data.map((x) => [
//                             parseISO((x as any).x).getTime(),
//                             x.y,
//                         ]),
//                     },
//                 ]
//                 // return dataAsync.value.map((x, index) => ({
//                 //     name: x.category,
//                 //     type: 'line',
//                 //     color: chartLineColors[index],
//                 //     data: x.data.map((point) => point.y),
//                 // }))
//             })

//             const categories = computed(() => {
//                 if (!dataAsync.value) return []

//                 return dataAsync.value.data.map((point) => String(point.x))
//             })

//             const setupChart = async () => {
//                 const chartEl = chartContainerRef.value
//                 if (!chartEl) return
//                 const Highcharts = await importHighcharts()
//                 chartInstance = Highcharts.chart({
//                     chart: {
//                         type: 'line',
//                         renderTo: chartEl as HTMLElement,
//                         marginTop: 40,
//                     },
//                     title: {
//                         text: '',
//                     },
//                     xAxis: {
//                         type: 'datetime',
//                         lineColor: '#DBDBDB',
//                     },
//                     legend: {
//                         align: 'right',
//                         verticalAlign: 'top',
//                     },
//                     yAxis: {
//                         color: '#DBDBDB',
//                         title: {
//                             text: '(%)',
//                             align: 'high',
//                             rotation: 0,
//                             reserveSpace: false,
//                             textAlign: 'left',
//                             y: -20,
//                         },
//                     },
//                     series: [],
//                     exporting: {
//                         enabled: false,
//                         filename: 'labour-participation-rate',
//                     },
//                     tooltip: {
//                         valueDecimals: 4,
//                     },
//                     responsive: {
//                         rules: [
//                             {
//                                 condition: {
//                                     maxWidth: 1024,
//                                 },
//                                 chartOptions: {
//                                     legend: {
//                                         align: 'center',
//                                         verticalAlign: 'bottom',
//                                     },
//                                 },
//                             },
//                         ],
//                     },
//                 })
//             }

//             const onDownload = () => {
//                 chartInstance?.downloadCSV()
//             }

//             const updateChart = () => {
//                 chartInstance?.update(
//                     {
//                         series: seriesData.value,
//                         // xAxis: {
//                         //     type: 'category',
//                         //     categories: categories.value,
//                         //     lineColor: '#DBDBDB',
//                         // },
//                     },
//                     true,
//                     true,
//                     true,
//                 )
//             }

//             onMounted(async () => {
//                 await setupChart()

//                 if (blockContext?.isInView) {
//                     updateChart()
//                 }
//             })

//             onBeforeUnmount(() => chartInstance?.destroy())

//             watch(
//                 () => ({ series: seriesData.value, categories: categories.value }),
//                 () => {
//                     if (!chartInstance) return
//                     updateChart()
//                 },
//             )

//             if (blockContext && !blockContext.isInView) {
//                 doIfInViewOrDefer(() => {
//                     updateChart()
//                 }, blockContext)
//             }

//             return {
//                 year,
//                 chartContainerRef,
//                 lastUpdatedLabel,
//                 onDownload,
//             }
//         },
//     }),
//     // async setupBlock(el, onTeardown, blockContext) {
//     //     const chartData = await getMarketDataLabourParticipationRate()

//     //     const latestValue = chartData.data[chartData.data.length - 1]
//     //     const beforeLatestValue = chartData.data[chartData.data.length - 2]
//     //     const valueDelta = (latestValue.y || 0) - (beforeLatestValue.y || 0)

//     //     const timestamp = chartData.lastUpdated
//     //     const date = new Date(timestamp)
//     //     const lastUpdatedValue = date
//     //         .toLocaleDateString('en-US', {
//     //             day: 'numeric',
//     //             month: 'short',
//     //             year: 'numeric',
//     //         })
//     //         .replace(',', '')
//     //     let trend: keyof typeof trendColorClasses & keyof typeof trendIconClasses = 'neutral'

//     //     if (valueDelta > 0) {
//     //         trend = 'positive'
//     //     } else if (valueDelta < 0) {
//     //         trend = 'negative'
//     //     }

//     //     const setupChart = async () => {
//     //         const chartEl = el.querySelector('.chart-container')
//     //         if (!chartEl) return

//     //         const Highcharts = await importHighcharts()

//     //         const chart = Highcharts.chart({
//     //             ...baseLineChartConfig,
//     //             chart: {
//     //                 ...baseLineChartConfig.chart,
//     //                 renderTo: chartEl as HTMLElement,
//     //             },
//     //             xAxis: {
//     //                 ...baseLineChartConfig.xAxis,
//     //                 categories: chartData.data.map((x) => x.x.toString()),
//     //             },
//     //             exporting: {
//     //                 enabled: false,
//     //                 filename: 'labour-participation-rate',
//     //             },
//     //             yAxis: {
//     //                 ...baseLineChartConfig.yAxis,
//     //                 title: {
//     //                     text: '(%)',
//     //                     align: 'high',
//     //                     rotation: 0,
//     //                     reserveSpace: false,
//     //                     textAlign: 'left',
//     //                     y: -20,
//     //                 },
//     //             },
//     //             series: [
//     //                 {
//     //                     name: chartData.yLabel,
//     //                     type: 'line',
//     //                     color: '#010066',
//     //                     data: chartData.data.map((x) => x.y),
//     //                 },
//     //             ],
//     //         })

//     //         const download = el.querySelector<HTMLButtonElement>('.download-btn')
//     //         if (download) {
//     //             download.addEventListener('click', () => {
//     //                 chart.downloadCSV()
//     //             })
//     //         }

//     //         onTeardown(() => {
//     //             chart.destroy()
//     //         })
//     //     }

//     //     doIfInViewOrDefer(() => {
//     //         setupChart()
//     // setupChartCounters(
//     //     el,
//     //     trend,
//     //     latestValue,
//     //     beforeLatestValue,
//     //     valueDelta,
//     //     timestamp,
//     //     lastUpdatedValue,
//     // )
//     //     }, blockContext)
//     // },
// })
import { importHighcharts } from '@/lib/highcharts'
import { BlockModule } from '../common'
import {
    baseLineChartConfig,
    countTo,
    doIfInViewOrDefer,
    setupChartCounters,
    trendColorClasses,
    trendIconClasses,
} from './common'
import { getMarketDataLabourParticipationRate } from '@/api'
import { defineComponent } from 'vue'
import ChartShareButtons from '@/components/website/ChartShareButtons.vue'
import { parseISO } from 'date-fns'

new BlockModule({
    selector: '.block-market-data-labour-participation-rate',
    vueComponent: defineComponent({
        components: { ChartShareButtons },
        setup() {},
    }),
    async setupBlock(el, onTeardown, blockContext) {
        const chartData = await getMarketDataLabourParticipationRate()

        const latestValue = chartData.data[chartData.data.length - 1]
        const beforeLatestValue = chartData.data[chartData.data.length - 2]
        const valueDelta = (latestValue.y || 0) - (beforeLatestValue.y || 0)

        const timestamp = chartData.lastUpdated
        const date = new Date(timestamp)
        const lastUpdatedValue = date
            .toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            })
            .replace(',', '')
        let trend: keyof typeof trendColorClasses & keyof typeof trendIconClasses = 'neutral'

        if (valueDelta > 0) {
            trend = 'positive'
        } else if (valueDelta < 0) {
            trend = 'negative'
        }

        const setupChart = async () => {
            const chartEl = el.querySelector('.chart-container')
            if (!chartEl) return

            const Highcharts = await importHighcharts()

            const chart = Highcharts.chart({
                ...baseLineChartConfig,
                chart: {
                    ...baseLineChartConfig.chart,
                    renderTo: chartEl as HTMLElement,
                },
                xAxis: {
                    type: 'datetime',
                    lineColor: '#DBDBDB',
                },
                exporting: {
                    enabled: false,
                    filename: 'labour-participation-rate',
                },
                yAxis: {
                    ...baseLineChartConfig.yAxis,
                    title: {
                        text: '(%)',
                        align: 'high',
                        rotation: 0,
                        reserveSpace: false,
                        textAlign: 'left',
                        y: -20,
                    },
                },
                series: [
                    {
                        name: chartData.yLabel,
                        type: 'line',
                        color: '#010066',
                        data: chartData.data.map((p) => [parseISO((p as any).x).getTime(), p.y]),
                    },
                ],
            })

            const download = el.querySelector<HTMLButtonElement>('.download-btn')
            if (download) {
                download.addEventListener('click', () => {
                    chart.downloadCSV()
                })
            }

            onTeardown(() => {
                chart.destroy()
            })
        }

        doIfInViewOrDefer(() => {
            setupChart()
            setupChartCounters(
                el,
                trend,
                latestValue,
                beforeLatestValue,
                valueDelta,
                timestamp,
                lastUpdatedValue,
            )
        }, blockContext)
    },
})
