import { importHighcharts } from '@/lib/highcharts'
import { BlockModule } from '../common'
import { doIfInViewOrDefer, chartLineColors } from './common'
import { getMarketDataForeignExUsdMyrInterbankIntradayRateDaily } from '@/api'
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, unref, watch } from 'vue'
import type { Chart, SeriesOptionsType } from 'highcharts'
import ChartShareButtons from '@/components/website/ChartShareButtons.vue'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { computedAsync } from '@vueuse/core'
import { format, parseISO } from 'date-fns'
import { useBlockContext } from '../common/composables'

new BlockModule({
    selector: '.block-market-data-foreign-ex-usd-myr-interbank-intraday-rate-daily',
    vueComponent: defineComponent({
        components: {
            ChartShareButtons,
            ShadcnSelect: Select,
            SelectContent,
            SelectGroup,
            SelectItem,
            SelectLabel,
            SelectTrigger,
            SelectValue,
        },
        setup() {
            const chartContainerRef = ref<HTMLElement>()
            const year = ref(new Date().getFullYear().toString())
            let chartInstance: Chart | null = null
            const blockContext = useBlockContext()

            const dataAsync = computedAsync(async (onCancel) => {
                const abortController = new AbortController()

                onCancel(() => abortController.abort())

                const data = await getMarketDataForeignExUsdMyrInterbankIntradayRateDaily(
                    unref(year),
                    abortController.signal,
                )

                return data
            })

            const lastUpdatedLabel = computed(() => {
                if (!dataAsync.value) return
                return format(dataAsync.value[0].lastUpdated, 'PP')
            })

            const seriesData = computed<SeriesOptionsType[]>(() => {
                if (!dataAsync.value) return []
                return dataAsync.value.map((x, index) => ({
                    name: x.category,
                    type: 'line',
                    color: chartLineColors[index],
                    data: x.data.map((p) => [parseISO((p as any).x).getTime(), p.y]),
                }))
            })

            const categories = computed(() => {
                if (!dataAsync.value) return []

                return dataAsync.value[0].data.map((point) => String(point.x))
            })

            const setupChart = async () => {
                const chartEl = chartContainerRef.value
                if (!chartEl) return
                const Highcharts = await importHighcharts()
                chartInstance = Highcharts.chart({
                    chart: {
                        type: 'line',
                        renderTo: chartEl as HTMLElement,
                        marginTop: 40,
                    },
                    title: {
                        text: '',
                    },
                    xAxis: {
                        type: 'datetime',
                        lineColor: '#DBDBDB',
                    },
                    legend: {
                        align: 'right',
                        verticalAlign: 'top',
                    },
                    yAxis: {
                        color: '#DBDBDB',
                        title: {
                            text: '(USD/MYR Interbank Intraday Rate Daily)',
                            align: 'high',
                            rotation: 0,
                            reserveSpace: false,
                            textAlign: 'left',
                            y: -20,
                        },
                    },
                    series: [],
                    exporting: {
                        enabled: false,
                        filename: 'foreign-ex-usd-myr-interbank-intraday-rate',
                    },
                    tooltip: {
                        valueDecimals: 4,
                    },
                    responsive: {
                        rules: [
                            {
                                condition: {
                                    maxWidth: 1024,
                                },
                                chartOptions: {
                                    legend: {
                                        align: 'center',
                                        verticalAlign: 'bottom',
                                    },
                                },
                            },
                        ],
                    },
                })
            }

            const onDownload = () => {
                chartInstance?.downloadCSV()
            }

            const updateChart = () => {
                chartInstance?.update(
                    {
                        series: seriesData.value,
                    },
                    true,
                    true,
                    true,
                )
            }

            onMounted(async () => {
                await setupChart()

                if (blockContext?.isInView) {
                    updateChart()
                }
            })

            onBeforeUnmount(() => chartInstance?.destroy())

            watch(
                () => ({ series: seriesData.value, categories: categories.value }),
                () => {
                    if (!chartInstance) return
                    updateChart()
                },
            )

            if (blockContext && !blockContext.isInView) {
                doIfInViewOrDefer(() => {
                    updateChart()
                }, blockContext)
            }

            return {
                year,
                chartContainerRef,
                lastUpdatedLabel,
                onDownload,
            }
        },
    }),
})
