import { importHighcharts } from '@/lib/highcharts'
import { BlockModule } from '../common'
import {
    baseLineChartConfig,
    countTo,
    doIfInViewOrDefer,
    setupChartCounters,
    trendColorClasses,
    trendIconClasses,
} from './common'
import { getMarketDataLabourUnemploymentRate } from '@/api'
import { defineComponent } from 'vue'
import ChartShareButtons from '@/components/website/ChartShareButtons.vue'
import { parseISO } from 'date-fns'

new BlockModule({
    selector: '.block-market-data-labour-unemployment-rate',
    vueComponent: defineComponent({
        components: { ChartShareButtons },
        setup() {},
    }),
    async setupBlock(el, onTeardown, blockContext) {
        const chartData = await getMarketDataLabourUnemploymentRate()

        const latestValue = chartData.data[chartData.data.length - 1]
        const beforeLatestValue = chartData.data[chartData.data.length - 2]
        const valueDelta = (latestValue.y || 0) - (beforeLatestValue.y || 0)

        const timestamp = chartData.lastUpdated
        const date = new Date(timestamp)
        const lastUpdatedValue = date
            .toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            })
            .replace(',', '')
        let trend: keyof typeof trendColorClasses & keyof typeof trendIconClasses = 'neutral'

        if (valueDelta > 0) {
            trend = 'positive'
        } else if (valueDelta < 0) {
            trend = 'negative'
        }

        const setupChart = async () => {
            const chartEl = el.querySelector('.chart-container')
            if (!chartEl) return

            const Highcharts = await importHighcharts()

            const chart = Highcharts.chart({
                ...baseLineChartConfig,
                chart: {
                    ...baseLineChartConfig.chart,
                    renderTo: chartEl as HTMLElement,
                },
                xAxis: {
                    type: 'datetime',
                    lineColor: '#DBDBDB',
                },
                exporting: {
                    enabled: false,
                    filename: 'labour-unemployment-rate',
                },
                yAxis: {
                    ...baseLineChartConfig.yAxis,
                    title: {
                        text: '(%)',
                        align: 'high',
                        rotation: 0,
                        reserveSpace: false,
                        textAlign: 'left',
                        y: -20,
                    },
                },
                series: [
                    {
                        name: chartData.yLabel,
                        type: 'line',
                        color: '#010066',
                        data: chartData.data.map((p) => [parseISO((p as any).x).getTime(), p.y]),
                    },
                ],
            })

            const download = el.querySelector<HTMLButtonElement>('.download-btn')
            if (download) {
                download.addEventListener('click', () => {
                    chart.downloadCSV()
                })
            }

            onTeardown(() => {
                chart.destroy()
            })
        }

        doIfInViewOrDefer(() => {
            setupChart()
            setupChartCounters(
                el,
                trend,
                latestValue,
                beforeLatestValue,
                valueDelta,
                timestamp,
                lastUpdatedValue,
            )
        }, blockContext)
    },
})
